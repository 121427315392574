import LogoText from '../assets/images/logo_text.png'
import FooterBg from '../assets/images/footer_bg.png'
import LinkedinBtn from '../assets/images/linkedin_btn.png'
import ReditBtn from '../assets/images/redit_btn.png'
import YoutubeBtn from '../assets/images/youtube_btn.png'
import TelegramBtn from '../assets/images/telegram_btn.png'
import FacebookBtn from '../assets/images/facebook_btn.png'
import DiscordBtn from '../assets/images/discord_btn.png'
import InstagramBtn from '../assets/images/instagram_btn.png'
import MediumBtn from '../assets/images/medium_btn.png'
import TwitterBtn from '../assets/images/twitter_btn.png'


function Footer () {
  return (
    <>
      <div className='w-full flex justify-center items-center px-2'>
        <img src={LogoText} alt='' />
      </div>
      <footer className='relative mb-0 w-full h-full'>
        <img
          className='absolute z-[-1] opacity-80 w-full px-2 lg:px-8 h-full bottom-0'
          src={FooterBg}
          alt=''
        />
        <div className='w-full px-10 lg:w-2/3 mx-auto justify-center items-center flex flex-col pb-6'>
          <div className='w-full flex justify-center items-center mt-[160px]'>
            <div className='flex flex-row items-center justify-center lg:justify-start gap-6 mx-auto lg:ml-0'>
              <img
                className='w-[40px] h-[40px] md:w-[55px] md:h-[55px] lg:w-[70px] lg:h-[70px]'
                src='logo.png'
                alt=''
              />
              <h1 className='w-full font-black text-xl lg:text-3xl text-[#333333]'>
                LOGARITHM GAMES
              </h1>
            </div>
          </div>
          <div className='w-full flex flex-col justify-center items-center'>
            
           
            <h1 className='pt-12 pb-8 font-bold text-xl text-[#333333]'>
              Follow Us
            </h1>

            <div className='flex flex-wrap items-center justify-center'>
              <a class="ml-[-20px] w-[70px] h-[70px]" href='https://discord.gg/7y2C3P7Jny' target="_blank" rel="noopener noreferrer">
			  <img
                className='cursor-pointer hover:brightness-105'
                src={DiscordBtn}
                alt=''
              /></a>
              <a class="ml-[-20px] w-[70px] h-[70px]" href='https://instagram.com/Logarithm.Games' target="_blank" rel="noopener noreferrer">
			  <img
                className='cursor-pointer hover:brightness-105'
                src={InstagramBtn}
                alt=''
              /></a>
			  <a class="ml-[-20px] w-[70px] h-[70px]" href='https://twitter.com/LogarithmGames' target="_blank" rel="noopener noreferrer">
			  <img
                className='cursor-pointer hover:brightness-105'
                src={TwitterBtn}
                alt=''
              /></a>
			  <a class="ml-[-20px] w-[70px] h-[70px]" href='https://www.youtube.com/@LogarithmGames' target="_blank" rel="noopener noreferrer">
			  <img
                className='cursor-pointer hover:brightness-105'
                src={YoutubeBtn}
                alt=''
              /></a>
              <a class="ml-[-20px] w-[70px] h-[70px]" href='https://facebook.com/profile.php?id=100086565892152' target="_blank" rel="noopener noreferrer">
			  <img
                className='cursor-pointer hover:brightness-105'
                src={FacebookBtn}
                alt=''
              /></a>
            </div>

            <p className='pt-16 text-center'>
              © 2023 LOGARITHM PTE. LTD. Singapore. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
